import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/MainLayout"
import Seo from "../components/WebSeo"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import * as styles from "./project-details.module.scss"

const ProjectDetails = ({ data }) => {
  const details = data.markdownRemark.frontmatter
  const html = data.markdownRemark.html

  const featuredImage = getImage(
    details.featured.childImageSharp.gatsbyImageData
  )
  return (
    <Layout>
      <Seo title="ProjectDetails" />
      <section className={styles.container}>
        <h2>{details.title}</h2>
        <GatsbyImage
          image={featuredImage}
          alt="featured image"
          className={styles.image}
        />
        <article dangerouslySetInnerHTML={{ __html: html }} />
        <small>{details.date}</small>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query MyQuery($slug: String) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      frontmatter {
        date
        title
        featured {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
      html
    }
  }
`

export default ProjectDetails
